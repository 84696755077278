import React from 'react';
import Category from '../common/category';
import './style.sass';

const icons = [
	{ name: 'icon_cheshirecat.png', alt: 'Icon - Cheshirecat' },
	{ name: 'icon_chesh_sunglasse.png', alt: 'Icon - Cheshirecat in sunglasses' },
	{ name: 'icon_chesh_3inrow.png', alt: 'Icon - Cheshirecat 3 in a row' },
	{ name: 'icon_chesh_hat.png', alt: 'Icon - Cheshirecat in a hat' },
	{ name: 'icon_doggymatch.png', alt: 'Icon - Doggy waving' },
	{ name: 'icon_doggy_pinapple.png', alt: 'Icon - Doggy wholding pinapple' },
	{ name: 'icon_kitty3.png', alt: 'Icon - Kitty holding 3 items' },
	{ name: 'icon_kitty_crown.png', alt: 'Icon - Kitty waving' },
	{ name: 'icon_Chameleon_sunset.png', alt: 'Icon - Chameleon on aquabike' },
	{ name: 'icon_octopusfield.png', alt: 'Icon - octopus field' },
	{ name: 'icon_fish.png', alt: 'Icon - Fish jumping' },
	{ name: 'icon_chameleon2.png', alt: 'Icon - Chameleon waving' },
	{ name: 'icon_fox.png', alt: 'Icon - Slots up fox' },
	{ name: 'icon_chicken.png', alt: 'Icon - Chicken waving' },
	{ name: 'icon_cash.png', alt: 'Icon - cash' },
	{ name: 'icon_cryptocoin_dog.png', alt: 'Icon - cryptocoin corgi' },
	{ name: 'icon_chicagogirl.png', alt: 'Icon - girl' },
	{ name: 'icon_pearl.png', alt: 'Icon - Shell with pearl' },
	{ name: 'icon_777_2021.png', alt: 'Icon - lucky 7s' },
	{ name: 'icon_buffalo.png', alt: 'Icon - bison slots' },
	{ name: 'icon_pepper_2021.png', alt: 'Icon - pepper' },
	{ name: 'icon_cherry.png', alt: 'Icon - neon cherry' },
	{ name: 'icon_777_2022.png', alt: 'Icon - lucky 7s' },
	{ name: 'icon_pepper_2022.png', alt: 'Icon - pepper' },
	{ name: 'icon_pot_2022.png', alt: 'Icon - pot full of cash winter' },
	{ name: 'icon_closechest_newcoins.png', alt: 'Icon - closed safe' },
	{ name: 'icon_cash_closechest.png', alt: 'Icon - closed chest' },
	{ name: 'icon_dynamite.png', alt: 'Icon - dynamite' },
	{ name: 'icon_clover.png', alt: 'Icon - clover' },
	{ name: 'icon_leprhat.png', alt: 'Icon - pot with Leprechaun hat' },
	{ name: 'icon_pot.png', alt: 'Icon - pot full of cash' },
	{ name: 'icon_coinbg23_512x512.png', alt: 'Icon - pot with clowet' },
];

const Icons: React.FC = () => {
	return (
		<Category
			categoryName={'icons'}
			data={icons}
			itemsLimit={4}
			assetsSource={'images'}
			hasModal={true}
		/>
	);
};

export default Icons;
