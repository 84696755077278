export enum AlertActionsEnum {
	SHOW_ALERT = 'SHOW_ALERT',
	HIDE_ALERT = 'HIDE_ALERT',
}

export enum MessageActionsEnum {
	SEND_MESSAGE = 'SEND_MESSAGE',
	SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
	SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL',
}
